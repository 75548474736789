import { TextSkeleton } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { type SxProps, type Theme } from "@mui/material";

export interface TotalPayLabelProps {
  /**
   * The formatted total pay for the shift.
   */
  totalPay: string | undefined;
  /**
   * Whether the pay data is being fetched.
   */
  isLoading?: boolean;
  /**
   * Custom styles to apply to the component.
   */
  sx?: SxProps<Theme>;
}

// TODO: Move to cbh-core as fast follow up to https://linear.app/clipboardhealth/issue/SPRTA-1362/update-shift-details-bottom-sheet
export function TotalPayLabel(props: TotalPayLabelProps) {
  const { totalPay, isLoading, sx } = props;

  if (isLoading) {
    return <TextSkeleton variant="body1" width="6ch" sx={sx} />;
  }

  return (
    <Text variant="body1" sx={sx}>
      {totalPay}
    </Text>
  );
}
