import type { IconType } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useLocalStorage } from "@src/appV2/lib/utils";
import { Button } from "@src/appV2/redesign/components/Button";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { SelectableBox } from "@src/appV2/redesign/components/SelectableBox";
import { PageContent } from "@src/appV2/redesign/WorkerShifts/components/PageContent";
import { useHistory } from "react-router-dom";

export const INTERESTED_WORK_TYPE_STORAGE_KEY = "interested-work-type";

export enum WorkTypeOption {
  PERMANENT_PLACEMENTS = "PERMANENT_PLACEMENTS",
  FLEX = "FLEX",
  PERMANENT_AND_FLEX = "PERMANENT_AND_FLEX",
}

interface WorkTypeConfig {
  value: WorkTypeOption;
  iconType: IconType;
  title: string;
  description: string;
}

export function InterestedWorkType() {
  const [selectedOption, setSelectedOption] = useLocalStorage<WorkTypeOption | undefined>(
    INTERESTED_WORK_TYPE_STORAGE_KEY,
    undefined
  );

  const history = useHistory();

  const isOptionSelected = selectedOption !== undefined;

  const workTypeConfigs: WorkTypeConfig[] = [
    {
      value: WorkTypeOption.PERMANENT_PLACEMENTS,
      iconType: "briefcase",
      title: "Finding a job",
      description: "Interview for a permanent position directly with the facility",
    },
    {
      value: WorkTypeOption.FLEX,
      iconType: "calendar-navigation",
      title: "Picking up shifts",
      description: "Book individual shifts as an independent contractor",
    },
    {
      value: WorkTypeOption.PERMANENT_AND_FLEX,
      iconType: "clipboard",
      title: "Both",
      description: "Get hired for a permanent job and/or work shifts as an independent contractor",
    },
  ];

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <PageContent>
          <Box sx={{ py: 12 }}>
            <Stack direction="column" spacing={8}>
              <Text variant="h3">What kind of work are you interested in?</Text>

              <Stack direction="column" spacing={4}>
                {workTypeConfigs.map((config) => (
                  <SelectableBox
                    key={config.value}
                    startIconType={config.iconType}
                    variant="secondary"
                    isSelected={selectedOption === config.value}
                    sx={{
                      px: 5,
                      py: 6,
                      backgroundColor: (theme) => theme.palette.background?.tertiary,
                      textAlign: "left",
                      borderRadius: (theme) => theme.borderRadius?.medium,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      border: (theme) => `1px solid ${theme.palette.border?.primary}`,
                    }}
                    onToggleSelect={() => {
                      setSelectedOption(config.value);
                    }}
                  >
                    <Stack direction="column" spacing={2}>
                      <Text semibold variant="h6">
                        {config.title}
                      </Text>
                      <Text variant="body2">{config.description}</Text>
                    </Stack>
                  </SelectableBox>
                ))}
              </Stack>
            </Stack>
          </Box>
          <Stack
            position="absolute"
            bottom={(theme) => theme.spacing(5)}
            left={0}
            justifyContent="center"
            alignItems="center"
            width="100%"
            sx={{ px: 5 }}
          >
            <Button
              fullWidth
              variant="contained"
              disabled={!isOptionSelected}
              onClick={() => {
                history.push(`${RootPaths.HOME}/agentSignupInfo1`);
              }}
            >
              Continue
            </Button>
          </Stack>
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
